import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Mixpanel from 'mixpanel-browser'
import Calculator from './pages/Calculator'

export default function App() {
  Mixpanel.init('b83711769e7a8b16db6cbfef00c1f5b8')
  Mixpanel.track('View')

  return (
    <Router>
      <Switch>
        <Route path="/" component={Calculator} />
      </Switch>
    </Router>
  )
}
