import styled from 'styled-components'

export const CalculatorPage = styled.div`
  background: #e5e5e5;
  display: grid;
  grid-template-columns: 20% 60% 20%;
  grid-template-areas: '. calculator legend';
  @media (max-width: 739px) {
    display: grid;
    grid-template-columns: 0% 100% 0%;
    grid-template-areas:
      '. calculator .'
      '. legend .';
    background: #ffffff;
  }
`
export const LegendBox = styled.div`
  margin-top: 90px;
  margin-left: 10px;
  grid-area: legend;
  display: flex;
  flex-direction: column;
  @media (max-width: 739px) {
    display: flex;
    flex-direction: row;
    margin-left: 75px;
    margin-top: 10px;
    grid-area: legend;
  }
`

export const LegendImg = styled.img`
  height: 166px;
  width: 150px;
  margin-bottom: 10px;
`

export const CalculatorBox = styled.div`
  padding: 0px 30px;
  background: #ffffff;
  grid-area: calculator;
`

export const TitleLinkBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: -10px;
`

export const TitleBox = styled.div`
  margin-left: 35px;
`

export const PageTitle = styled.h1`
  margin-left: 15px;
  text-align: center;
`

export const Title = styled.h2`
  font-size: 16px;
  margin-left: 10px;
`
export const IndvCalculatorBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
  margin-left: 35px;
  margin-bottom: 20px;
  @media (max-width: 889px) {
    flex-direction: column;
    align-items: flex-start;
  }
`
