import styled from 'styled-components'

export const SwapButtonBox = styled.div`
  @media (max-width: 889px) {
    transform: rotate(90deg);
    margin-left: 85px;
  }
  margin-top: 10px;
  margin-left: 10px;
`
