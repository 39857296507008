import React from 'react'
import styled from 'styled-components'
import CircularProgress from '@material-ui/core/CircularProgress'

export default function Loading() {
  return (
    <LoadingScreen>
      <CircularProgress size={55} />
    </LoadingScreen>
  )
}

const LoadingScreen = styled.div`
  position: absolute; /* or absolute */
  top: 65%;
  left: 50%;
`
