import { red } from '@material-ui/core/colors'
import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
  palette: {
    fireIntensity: {
      low: '#fffebb',
      moderate: '#f7cc6f',
      considerable: '#ef914f',
      high: '#f07775',
      extreme: '#bd0026'
    },
    fireIntensityText: {
      low: '#5b5901',
      moderate: '#4c3824',
      considerable: '#2b1a0d',
      high: '#2b1a0d',
      extreme: '#ffffff'
    },
    flameLength: {
      low: '#f8eccc',
      moderate: '#e5cb91',
      considerable: '#d3a76f',
      high: '#9e6c2f',
      extreme: '#765123'
    },
    flameLengthText: {
      low: '#614a0f',
      moderate: '#4B3711',
      considerable: '#222216',
      high: '#050503',
      extreme: '#ffffff'
    },
    primary: {
      main: '#192383'
    },
    secondary: {
      main: '#19857b'
    },
    error: {
      main: red.A400
    },
    background: {
      default: '#fff'
    }
  }
})

export default theme
