import React, { Suspense } from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import theme from '../theme'
import Loading from '../components/Loading'

function AppProviders({ children }) {
  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <Suspense fallback={<Loading />}>{children}</Suspense>
      </ThemeProvider>
    </>
  )
}

AppProviders.propTypes = {
  children: PropTypes.element.isRequired
}

export default AppProviders
