import React from 'react'
import PropTypes from 'prop-types'
import { OutputBox, OutputLabel } from './Output.styles.js'
import theme from '../theme.js'

const Output = ({
  title,
  value,
  breakpoints,
  backgroundPalette,
  textPalette,
  step
}) => {
  const backgroundColor =
    !breakpoints || !backgroundPalette
      ? '#f1f1f1'
      : value >= breakpoints[3]
      ? theme.palette[backgroundPalette].extreme
      : value >= breakpoints[2] && value <= breakpoints[3] - step
      ? theme.palette[backgroundPalette].high
      : value >= breakpoints[1] && value <= breakpoints[2] - step
      ? theme.palette[backgroundPalette].considerable
      : value >= breakpoints[0] && value <= breakpoints[1] - step
      ? theme.palette[backgroundPalette].moderate
      : theme.palette[backgroundPalette].low

  const outputText =
    !breakpoints || !textPalette
      ? '#333333'
      : value >= breakpoints[3]
      ? theme.palette[textPalette].extreme
      : value >= breakpoints[2] && value <= breakpoints[3] - step
      ? theme.palette[textPalette].high
      : value >= breakpoints[1] && value <= breakpoints[2] - step
      ? theme.palette[textPalette].considerable
      : value >= breakpoints[0] && value <= breakpoints[1] - step
      ? theme.palette[textPalette].moderate
      : theme.palette[textPalette].low

  return (
    <div>
      <OutputLabel>{title}</OutputLabel>
      <OutputBox backgroundColor={backgroundColor} outputText={outputText}>
        {value}
      </OutputBox>
    </div>
  )
}

Output.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  breakpoints: PropTypes.array,
  backgroundPalette: PropTypes.string,
  textPalette: PropTypes.string,
  step: PropTypes.number
}

export default Output
