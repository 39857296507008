import React from 'react'
import SwapHorizIcon from '@material-ui/icons/SwapHoriz'
import PropTypes from 'prop-types'
import { SwapButtonBox } from './SwapButton.styles.js'

const SwapButton = ({ onClick }) => (
  <SwapButtonBox>
    <SwapHorizIcon onClick={onClick} />
  </SwapButtonBox>
)

SwapButton.propTypes = { onClick: PropTypes.func.isRequired }

export default SwapButton
