import React, { useState, useEffect } from 'react'
import Mixpanel from 'mixpanel-browser'
import UserInput from '../components/UserInput.js'
import Output from '../components/Output.js'
import LinkOff from '../components/LinkOff.js'
import LinkOn from '../components/LinkOn.js'
import SwapButton from '../components/SwapButton.js'
import FireIntensityLegend from '../images/fire-intensity-legend.png'
import FlameLengthLegend from '../images/flame-length-legend.png'
import {
  calculateCSFI,
  calculateSFFL,
  calculateSFI,
  calculateCSH,
  calculateFI,
  calculateWI
} from '../helpers/equations.js'

import {
  CalculatorPage,
  LegendBox,
  LegendImg,
  CalculatorBox,
  TitleLinkBox,
  IndvCalculatorBox,
  TitleBox,
  PageTitle,
  Title
} from './Calculator.styles.js'

export default function Calculator() {
  //states for first calculator: Critical Surface Fire Intensity(csfi)
  const [foilarMoistureContent, setFoilarMoistureContent] = useState('0')
  const [liveCrownBaseHeight, setLiveCrownBaseHeight] = useState('0')
  const [csfiOutput, setCsfiOutput] = useState('0')

  //states for second calculator: Surface Fire Flame Length(sffl)
  const [csfiLink, setCsfiLink] = useState(false)
  const [csfiInput, setCsfiInput] = useState('0')
  const [sfflOutput, setSfflOutput] = useState('0')

  //states for third calculator: Surface Fire Intensity(sfi)
  const [flameLengthLink, setFlameLengthLink] = useState(false)
  const [sfflInput, setSfflInput] = useState('0')
  const [sfiOutput, setSfiOutput] = useState('0')

  //states for fourth calculator: Crown Scorch Height
  const [sfiLink, setSfiLink] = useState(false)
  const [sfiInput, setSfiInput] = useState('0')
  const [crownScorchHeight, setCrownScorchHeight] = useState('0')
  const [swap, setSwap] = useState(false)

  //states for fifth calculator: Wildfire Intensity
  const [weightOfFuel, setWeightOfFuel] = useState('0')
  const [rateOfSpread, setRateOfSpread] = useState('0')

  //calculating CSFI & monitoring Critical Surface Fire Intensity link (csfiLink)
  useEffect(() => {
    const csfi = calculateCSFI(liveCrownBaseHeight, foilarMoistureContent)
    setCsfiOutput(csfi)

    if (csfiLink) {
      setCsfiInput(csfi)
    }
  }, [liveCrownBaseHeight, foilarMoistureContent, csfiLink, setCsfiOutput])

  //monitoring Critical Surface Fire Intensity input (csfiInput)
  useEffect(() => {
    if (!csfiLink) return
    if (csfiInput !== csfiOutput) {
      setCsfiLink(false)
    }
  }, [csfiLink, csfiInput, csfiOutput])

  //calculating Surface Fire Flame Length (sffl) & monitoring flameLength Link
  useEffect(() => {
    const sffl = calculateSFFL(csfiInput)
    setSfflOutput(sffl)

    if (flameLengthLink) {
      setSfflInput(sffl)
    }
  }, [csfiInput, setSfflOutput, flameLengthLink])

  //monitoring Surface Fire Flame Length input (sfflInput)
  useEffect(() => {
    if (!flameLengthLink) return
    if (sfflInput !== sfflOutput) {
      setFlameLengthLink(false)
    }
  }, [flameLengthLink, sfflInput, sfflOutput])

  //calculating Surface Fire Intensity (sfi) & monitoring sfiLink
  useEffect(() => {
    const sfi = calculateSFI(sfflInput)
    setSfiOutput(sfi)

    if (sfiLink) {
      setSfiInput(sfi)
    }
  }, [sfflInput, setSfiOutput, sfiLink])

  //monitoring Surface Fire Intensity input (sfiInput)
  useEffect(() => {
    if (!sfiLink) return
    if (sfiInput !== sfiOutput) {
      setSfiLink(false)
    }
  }, [sfiInput, sfiLink, sfiOutput])

  //monitoring swap click
  useEffect(() => {
    if (swap) {
      setSfiLink(false)
    }
  }, [swap])

  // all the click handlers
  function linkCSFI() {
    setCsfiInput(calculateCSFI(liveCrownBaseHeight, foilarMoistureContent))
    setCsfiLink(prevCsfiLink => !prevCsfiLink)
    Mixpanel.track('CSFI link pressed')
  }

  function unlinkCSFI() {
    setCsfiInput('0')
    setCsfiLink(prevCsfiLink => !prevCsfiLink)
    Mixpanel.track('CSFI unlink pressed')
  }

  function linkSFFL() {
    setSfflInput(calculateSFFL(csfiInput))
    setFlameLengthLink(prevFireLenghtLink => !prevFireLenghtLink)
    Mixpanel.track('SFFL link pressed')
  }

  function unlinkSFFL() {
    setSfflInput('0')
    setFlameLengthLink(prevFireLenghtLink => !prevFireLenghtLink)
    Mixpanel.track('SFFL unlink pressed')
  }

  function linkSFI() {
    setSfiInput(calculateSFI(sfflInput))
    setSfiLink(prevSfiLink => !prevSfiLink)
    Mixpanel.track('SFI link pressed')
  }

  function unlinkSFI() {
    setSfiInput('0')
    setSfiLink(prevSfiLink => !prevSfiLink)
    Mixpanel.track('SFI unlink pressed')
  }

  function clickSwap() {
    setSwap(prevSwap => !prevSwap)
    Mixpanel.track('Swap button pressed')
  }

  function onBlur(label, value) {
    Mixpanel.track('User input', { [label]: value })
  }

  return (
    <CalculatorPage>
      <CalculatorBox>
        <PageTitle>Critical Surface Fire Intensity Calculator</PageTitle>
        <TitleBox>
          <Title>
            Critical Surface Fire Intensity for Initial Crown Combustion [Van
            Wagner 1977]
          </Title>
        </TitleBox>
        <IndvCalculatorBox>
          <UserInput
            id="Foilar Moisture Content (%)"
            label="Foilar Moisture Content (%)"
            value={foilarMoistureContent}
            inputProps={{ step: 1 }}
            onChange={e => setFoilarMoistureContent(e.target.value)}
            onBlur={() =>
              onBlur('foilarMoistureContent', foilarMoistureContent)
            }
          />
          <UserInput
            id="Live Crown Base Height (m)"
            label="Live Crown Base Height (m)"
            value={liveCrownBaseHeight}
            inputProps={{ step: 0.1 }}
            onChange={e => setLiveCrownBaseHeight(e.target.value)}
            onBlur={() => onBlur('liveCrownBaseHeight', liveCrownBaseHeight)}
          />
          <Output
            title="Critical Surface Fire Intensity (kW/m)"
            value={csfiOutput}
            breakpoints={[250, 500, 1000, 1500]}
            backgroundPalette={'fireIntensity'}
            textPalette={'fireIntensityText'}
            step={0.1}
          />
        </IndvCalculatorBox>

        <TitleLinkBox>
          {csfiLink ? (
            <LinkOn onClick={unlinkCSFI} />
          ) : (
            <LinkOff onClick={linkCSFI} />
          )}
          <Title>Surface Fire Flame Length [Byram 1959]</Title>
        </TitleLinkBox>
        <IndvCalculatorBox>
          <UserInput
            id="Fire Intensity (kW/m)"
            label="Fire Intensity (kW/m)"
            value={csfiInput}
            inputProps={{ step: 0.1 }}
            onChange={e => setCsfiInput(e.target.value)}
            onBlur={() => onBlur('csfiInput', csfiInput)}
          />
          <Output
            title="Surface Fire Flame Length (m)"
            value={sfflOutput}
            breakpoints={[1.01, 1.36, 1.87, 2.25]}
            backgroundPalette={'flameLength'}
            textPalette={'flameLengthText'}
            step={0.01}
          />
        </IndvCalculatorBox>

        <TitleLinkBox>
          {flameLengthLink ? (
            <LinkOn onClick={unlinkSFFL} />
          ) : (
            <LinkOff onClick={linkSFFL} />
          )}
          <Title>
            Surface Fire Intensity based on Flame Length [Alexander 1982]
          </Title>
        </TitleLinkBox>

        <IndvCalculatorBox>
          <UserInput
            id="Flame Length (m)"
            label="Flame Length (m)"
            value={sfflInput}
            inputProps={{ step: 0.01 }}
            onChange={e => setSfflInput(e.target.value)}
            onBlur={() => onBlur('sfflInput', sfflInput)}
          />
          <Output
            title="Surface Fire Intensity (kW/m)"
            value={sfiOutput}
            breakpoints={[250, 500, 1000, 1500]}
            backgroundPalette={'fireIntensity'}
            textPalette={'fireIntensityText'}
            step={1}
          />
        </IndvCalculatorBox>

        <TitleLinkBox>
          {!swap && sfiLink ? (
            <LinkOn onClick={unlinkSFI} />
          ) : (
            <LinkOff onClick={linkSFI} disabled={swap} />
          )}
          <Title>Crown Scorch Height [Van Wagner 1973]</Title>
        </TitleLinkBox>
        {swap ? (
          <IndvCalculatorBox>
            <UserInput
              id="Lethal Crown Scorch Height (m)"
              label="Lethal Crown Scorch Height (m)"
              value={crownScorchHeight}
              inputProps={{ step: 0.01 }}
              onChange={e => setCrownScorchHeight(e.target.value)}
              onBlur={() => onBlur('crownScorchHeight', crownScorchHeight)}
            />
            <SwapButton onClick={clickSwap} />
            <Output
              title="Fire Intensity (kW/m)"
              value={calculateFI(crownScorchHeight)}
              breakpoints={[250, 500, 1000, 1500]}
              backgroundPalette={'fireIntensity'}
              textPalette={'fireIntensityText'}
              step={1}
            />
          </IndvCalculatorBox>
        ) : (
          <IndvCalculatorBox>
            <UserInput
              id="Fire Intensity (kW/m)"
              label="Fire Intensity (kW/m)"
              value={sfiInput}
              inputProps={{ step: 0.1 }}
              onChange={e => setSfiInput(e.target.value)}
              onBlur={() => onBlur('sfiInput', sfiInput)}
            />
            <SwapButton onClick={clickSwap} />
            <Output
              title="Lethal Crown Scorch Height (m)"
              value={calculateCSH(sfiInput)}
            />
          </IndvCalculatorBox>
        )}
        <TitleBox>
          <Title>Wildfire Intensity [Byram]</Title>
        </TitleBox>
        <IndvCalculatorBox>
          <UserInput
            id="Weight of the Fuel (kg/m^2)"
            label="Weight of the Fuel (kg/m^2)"
            value={weightOfFuel}
            inputProps={{ step: 0.01 }}
            onChange={e => setWeightOfFuel(e.target.value)}
            onBlur={() => onBlur('weightOfFuel', weightOfFuel)}
          />
          <UserInput
            id="Rate of Spread (m/min)"
            label="Rate of Spread (m/min)"
            value={rateOfSpread}
            inputProps={{ step: 0.01 }}
            onChange={e => setRateOfSpread(e.target.value)}
            onBlur={() => onBlur('rateOfSpread', rateOfSpread)}
          />
          <Output
            title="Wildfire Intensity (kW/m)"
            value={calculateWI(weightOfFuel, rateOfSpread)}
            breakpoints={[250, 500, 1000, 1500]}
            backgroundPalette={'fireIntensity'}
            textPalette={'fireIntensityText'}
            step={1}
          />
        </IndvCalculatorBox>
      </CalculatorBox>
      <LegendBox>
        <LegendImg
          src={FireIntensityLegend}
          alt="Colour legend for  Fire Intensity output"
        />
        <LegendImg
          src={FlameLengthLegend}
          alt="Colour legend for  Flame Length output"
        />
      </LegendBox>
    </CalculatorPage>
  )
}
