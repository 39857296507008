import React from 'react'
import LinkOffIcon from '@material-ui/icons/LinkOff'
import PropTypes from 'prop-types'
import { LinkOffBox } from './LinkOff.styles.js'

const LinkOff = ({ onClick, disabled }) => (
  <LinkOffBox disabled={disabled}>
    <LinkOffIcon onClick={onClick} />
  </LinkOffBox>
)

LinkOff.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool
}

export default LinkOff
