import styled from 'styled-components'

export const OutputBox = styled.div`
  margin-left: 10px;
  box-sizing: border-box;
  border-radius: 5px;
  width: 195px;
  height: 56px;
  padding: 18px 10px;
  font-family: sans-serif;
  font-weight: bold;
  font-size: 16px;
  color: ${props => (props.outputText ? props.outputText : '#333333')};
  background-color: ${props =>
    props.backgroundColor ? props.backgroundColor : '#f1f1f1'};
`

export const OutputLabel = styled.div`
  font-family: sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #767676;
  margin: 15px 0px 0px 10px;
`
