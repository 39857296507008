import React from 'react'
import PropTypes from 'prop-types'
import { UserInputField } from './UserInput.styles.js'

const UserInput = ({ label, value, onChange, inputProps, onBlur }) => (
  <UserInputField
    variant="outlined"
    type="number"
    id={label}
    label={label}
    value={value}
    inputProps={inputProps}
    onChange={onChange}
    onBlur={onBlur}
  />
)

UserInput.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  inputProps: PropTypes.shape({
    step: PropTypes.number
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func
}

export default UserInput
