import React from 'react'
import LinkIcon from '@material-ui/icons/Link'
import PropTypes from 'prop-types'
import { LinkOnBox } from './LinkOn.styles.js'

const LinkOn = ({ onClick }) => (
  <LinkOnBox>
    <LinkIcon onClick={onClick} />
  </LinkOnBox>
)

LinkOn.propTypes = { onClick: PropTypes.func.isRequired }

export default LinkOn
