//Critical Surface Fire Intensity equation
function calculateCSFI(liveCrownBaseHeight, foilarMoistureContent) {
  return Math.pow(
    0.01 * liveCrownBaseHeight * (460 + 26 * foilarMoistureContent),
    1.5
  ).toFixed(2)
}

//Surface Fire Flame Length equation
function calculateSFFL(criticalSurfaceFireIntensity) {
  return (0.0775 * Math.pow(criticalSurfaceFireIntensity, 0.46)).toFixed(2)
}
//Surface Fire Intensity Based on Flame Length equation
function calculateSFI(fireLength) {
  return (300 * Math.pow(fireLength, 2)).toFixed(2)
}
//Crown Scorch Height equation
function calculateCSH(surfaceFireIntensity) {
  return (0.1483 * Math.pow(surfaceFireIntensity, 2 / 3)).toFixed(2)
}
//Fire Intensity equation based on Crown Scorch Height
function calculateFI(crownScorchHeight) {
  return (17.51 * Math.pow(crownScorchHeight, 1.5)).toFixed(2)
}
//Wildfire Intensity equation
function calculateWI(weightOfFuel, rateOfSpread) {
  return (300 * weightOfFuel * rateOfSpread).toFixed(2)
}

export {
  calculateCSFI,
  calculateSFFL,
  calculateSFI,
  calculateCSH,
  calculateFI,
  calculateWI
}
