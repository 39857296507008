import TextField from '@material-ui/core/TextField'
import styled from 'styled-components'

export const UserInputField = styled(TextField)`
  margin: 15px 0px 0px 10px;
  .MuiOutlinedInput-notchedOutline {
    border-color: #fedcdc;
  }
  .MuiOutlinedInput-root {
    width: 200px;
    height: 56px;
    color: grey;
  }

  label {
    color: black;
  }
`
